import { IProductSizesTeaserProps } from '@root/components/ProductSizesTeaser/models';
import Joi from 'joi';

export const productSizesTeaserSchema = Joi.object<IProductSizesTeaserProps>({
  teaserTitle: Joi.string().required(),
  teaserDescription: Joi.string().required(),
  teaserImageAlt: Joi.string().required(),
  teaserImage: Joi.object({
    url: Joi.string().uri().required(),
  }).required(),
}).options({ stripUnknown: true });
