import React, { FC } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';
import { Typography } from '@design-system/typography';
import ControlledModal from '@root/components/ControlledModal';
import ProductSizesList from '@root/components/ProductSizesList';
import ProductSizesTeaser from '@root/components/ProductSizesTeaser';
import { ISizeVariantProps } from '@root/components/SizeVariant/models';
import useJoiValidation from '@root/hooks/useJoiValidation';
import { gatsbyImageMapper } from '@theme-husky/mappers/image';
import classNames from 'classnames';

import { IProductSizesSectionProps } from './models';
import { productSizesSectionSchema } from './ProductSizesSection.schema';

import './ProductSizesSection.scss';

const ProductSizesSection: FC<IProductSizesSectionProps> = ({
  ctaOpen,
  ctaClose,
  trialPackSize,
  trialPackName,
  experiencePackName,
  experiencePackSize,
  superValuePackName,
  superValuePackSize,
  conveniencePackSize,
  conveniencePackName,
  valuePackSize,
  valuePackName,
  modalTitle,
  modalImage,
  modalDescription,
  inTeaser,
  teaserImage,
  teaserDescription,
  teaserTitle,
  teaserImageAlt,
}) => {
  const {
    data,
  }: {
    data: IProductSizesSectionProps;
  } = useJoiValidation(productSizesSectionSchema, 'ProductSizesSection', {
    ctaOpen,
    ctaClose,
    trialPackSize,
    trialPackName,
    experiencePackName,
    experiencePackSize,
    superValuePackName,
    superValuePackSize,
    conveniencePackSize,
    conveniencePackName,
    valuePackSize,
    valuePackName,
    modalTitle,
    modalImage,
    modalDescription,
    inTeaser,
    teaserImage,
    teaserDescription,
    teaserTitle,
    teaserImageAlt,
  });

  const modalInsideTeaser: boolean = Boolean(
    data.inTeaser && data.teaserImage?.url && data.teaserDescription && data.teaserTitle
  );
  const sizesVariants: ISizeVariantProps[] = [
    { name: data.trialPackName, size: data.trialPackSize },
    { name: data.experiencePackName, size: data.experiencePackSize },
    { name: data.conveniencePackName, size: data.conveniencePackSize },
    { name: data.valuePackName, size: data.valuePackSize },
    { name: data.superValuePackName, size: data.superValuePackSize },
  ].filter((variant: ISizeVariantProps) => variant.name && variant.size);

  return (
    <div
      className={classNames('product-sizes', {
        'product-sizes__with-teaser': modalInsideTeaser,
      })}
    >
      {inTeaser ? (
        <ProductSizesTeaser
          teaserTitle={data.teaserTitle || ''}
          teaserDescription={data.teaserDescription || ''}
          teaserImage={data.teaserImage || { url: '' }}
          teaserImageAlt={data.teaserImageAlt || ''}
        />
      ) : null}
      <ControlledModal
        openButtonLabel={data.ctaOpen}
        closeButtonLabel={data.ctaClose}
        className="product-sizes-modal"
      >
        <>
          {data.modalImage ? (
            <ExtendableImage
              className="cds-modal-window__image"
              element={gatsbyImageMapper({
                alt: data.modalTitle || 'Product Image',
                image: data.modalImage,
                objectFit: 'contain',
              })}
            />
          ) : null}
          {data.modalTitle ? (
            <Typography
              className="cds-modal-window__title"
              tag="h3"
              variant="headline"
              size="l"
              content={data.modalTitle}
              align="start"
              weight="bold"
              wrapping="default"
            />
          ) : null}
          {data.modalDescription ? (
            <Typography
              className="cds-modal-window__description"
              tag="div"
              variant="body"
              size="m"
              content={data.modalDescription}
              align="start"
              weight="medium"
              wrapping="default"
            />
          ) : null}
          <ProductSizesList items={sizesVariants} />
        </>
      </ControlledModal>
    </div>
  );
};

export default ProductSizesSection;
