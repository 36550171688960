import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from '@design-system/modal';
import { isBrowser } from '@phx-husky/browser-helpers';
import { getLocalStorageData, setLocalStorageData } from '@phx-husky/local-storage';
import { useScreenRecognition } from '@phx-husky/use-screen-recognition';
import useJoiValidation from '@root/hooks/useJoiValidation';

import { ISplashScreenProps } from './models';
import { splashScreenSchema } from './SplashScreen.schema';

import './SplashScreen.scss';

const SplashScreen: FC<ISplashScreenProps> = ({
  ariaClose,
  isModalClosedAfterVideoEnd,
  isMobileOnly,
  isModalShowedOnlyOnce,
  videoUrl,
  noSupportMessage,
  onCloseLocalStorageValue,
  isVideoAutoplayed,
  fallbackVideoImage,
  useFallbackImage,
}) => {
  const { data }: { data: ISplashScreenProps } = useJoiValidation(
    splashScreenSchema,
    'SplashScreen',
    {
      ariaClose,
      isModalShowedOnlyOnce,
      isMobileOnly,
      isModalClosedAfterVideoEnd,
      videoUrl,
      noSupportMessage,
      isVideoAutoplayed,
      onCloseLocalStorageValue,
      fallbackVideoImage,
      useFallbackImage,
    }
  );

  const [isModalVisible, setIsModalVisible] = useState<boolean>(
    data.isModalShowedOnlyOnce ? !getLocalStorageData(data.onCloseLocalStorageValue) : true
  );

  const { isMobile } = useScreenRecognition();

  const shouldShowModal = useMemo(
    () => (isMobile && data.isMobileOnly) || !data.isMobileOnly,
    [data.isMobileOnly, isMobile]
  );

  const handleModalClose = useCallback(() => {
    document.body.style.overflow = 'auto';

    setIsModalVisible(false);

    if (data.isModalShowedOnlyOnce) {
      setLocalStorageData(data.onCloseLocalStorageValue, true);
    }
  }, [data.isModalShowedOnlyOnce, data.onCloseLocalStorageValue]);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModalVisible]);

  return isModalVisible && shouldShowModal ? (
    <Modal
      isShowing={isBrowser()}
      fullPage
      close={handleModalClose}
      closeModal={handleModalClose}
      className="splash-screen"
      closeAriaLabel={data.ariaClose}
      closeIconSize="large"
    >
      {data.videoUrl ? (
        <div className="splash-screen__wrapper">
          <video
            className="splash-screen__video"
            preload="auto"
            muted
            playsInline
            autoPlay={data.isVideoAutoplayed}
            controls={!data.isVideoAutoplayed}
            src={data.videoUrl}
            onEnded={data.isModalClosedAfterVideoEnd ? handleModalClose : undefined}
            poster={
              (data.useFallbackImage &&
                data?.fallbackVideoImage?.gatsbyImageData?.images?.fallback?.src) ||
              undefined
            }
          >
            {data.noSupportMessage}
          </video>
        </div>
      ) : null}
    </Modal>
  ) : null;
};

export default SplashScreen;
