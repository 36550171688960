import { IVideoTeaserProps } from '@root/components/VideoTeaser/models';
import Joi from 'joi';

export const videoTeaserSchema = Joi.object<IVideoTeaserProps>({
  videoUrl: Joi.string().required(),
  teaserSubtitle: Joi.string().required(),
  teaserTitle: Joi.string().required(),
  noSupportMessage: Joi.string().required(),
  fallbackVideoImage: Joi.object({
    url: Joi.string().uri().optional(),
  }).optional(),
}).options({ stripUnknown: true });
