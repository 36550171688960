import React, { FC } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';
import { Typography } from '@design-system/typography';
import useJoiValidation from '@root/hooks/useJoiValidation';
import { gatsbyImageMapper } from '@theme-husky/mappers/image';

import { IProductSizesTeaserProps } from './models';
import { productSizesTeaserSchema } from './ProductSizesTeaser.schema';

import './ProductSizesTeaser.scss';

const ProductSizesTeaser: FC<IProductSizesTeaserProps> = ({
  teaserTitle,
  teaserDescription,
  teaserImage,
  teaserImageAlt,
}) => {
  const {
    data,
  }: {
    data: IProductSizesTeaserProps;
  } = useJoiValidation(productSizesTeaserSchema, 'ProductSizesTeaser', {
    teaserTitle,
    teaserDescription,
    teaserImage,
    teaserImageAlt,
  });

  return (
    <div className="product-sizes-teaser">
      <div className="product-sizes-teaser__text">
        <Typography
          className="product-sizes-teaser__title"
          tag="h2"
          variant="body"
          size="xl"
          content={data.teaserTitle}
          align="start"
          weight="bold"
          wrapping="default"
        />
        <Typography
          className="product-sizes-teaser__description"
          tag="h3"
          variant="body"
          size="m"
          content={data.teaserDescription}
          align="start"
          weight="regular"
          wrapping="default"
        />
      </div>
      <ExtendableImage
        element={gatsbyImageMapper({
          alt: data.teaserImageAlt,
          image: data.teaserImage,
          objectFit: 'contain',
        })}
      />
    </div>
  );
};

export default ProductSizesTeaser;
