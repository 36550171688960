import React, { FC } from 'react';
import { Typography } from '@design-system/typography';
import useJoiValidation from '@root/hooks/useJoiValidation';

import { ISizeVariantProps } from './models';
import { sizeVariantSchema } from './SizeVariant.schema';

import './SizeVariant.scss';

const SizeVariant: FC<ISizeVariantProps> = ({ name, size }) => {
  const { data }: { data: ISizeVariantProps } = useJoiValidation(sizeVariantSchema, 'SizeVariant', {
    name,
    size,
  });

  return (
    <div className="size-variant">
      <Typography
        tag="div"
        variant="body"
        size="l"
        content={data.name}
        align="start"
        weight="bold"
        wrapping="default"
      />
      <Typography
        tag="div"
        variant="body"
        size="l"
        content={data.size}
        align="start"
        weight="regular"
        wrapping="default"
      />
    </div>
  );
};

export default SizeVariant;
