import React, { FC, useCallback, useState } from 'react';
import { Button } from '@design-system/button';
import { Modal } from '@design-system/modal';
import useJoiValidation from '@root/hooks/useJoiValidation';
import classNames from 'classnames';

import { controlledModalSchema } from './ControlledModal.schema';
import { IControlledModalProps } from './models';

const ControlledModal: FC<IControlledModalProps> = ({
  openButtonLabel,
  closeButtonLabel,
  children,
  className,
}) => {
  const { data }: { data: IControlledModalProps } = useJoiValidation(
    controlledModalSchema,
    'ControlledModal',
    {
      openButtonLabel,
      closeButtonLabel,
    }
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);
  }, []);

  return (
    <div className={className}>
      <Button
        onClick={toggleModal}
        size="large"
        variant="primary"
        iconSuffix={{ props: { icon: 'arrowRight' } }}
      >
        {data.openButtonLabel}
      </Button>
      <Modal
        isShowing={isModalOpen}
        close={toggleModal}
        closeModal={toggleModal}
        closeAriaLabel={data.closeButtonLabel}
        closeIconSize="large"
        className={classNames(className && `${className}__modal`)}
      >
        {children}
        <Button onClick={toggleModal} size="large" variant="primary">
          {data.closeButtonLabel}
        </Button>
      </Modal>
    </div>
  );
};

export default ControlledModal;
