import { IWhereToBuyProps } from '@root/components/WhereToBuy/models';
import Joi from 'joi';

const stringRequired = Joi.string().required();
const uriRequired = Joi.string().uri().required();
const imgSchema = {
  url: uriRequired,
  filename: Joi.string().required(),
};

const requiredImageSchema = Joi.object(imgSchema).required();

export const whereToBuySchema = Joi.object<IWhereToBuyProps>({
  ctaOpen: stringRequired,
  ctaClose: stringRequired,
  ctaBuyOnline: stringRequired,
  ctaBuyNearby: stringRequired,
  imageAlt: stringRequired,
  imageMobileBuyOnline: requiredImageSchema,
  imageMobileBuyNearby: requiredImageSchema,
  imageDesktop: requiredImageSchema,
}).options({ stripUnknown: true });
