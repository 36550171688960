import React, { FC } from 'react';
import SizeVariant from '@root/components/SizeVariant';
import { ISizeVariantProps } from '@root/components/SizeVariant/models';
import useJoiValidation from '@root/hooks/useJoiValidation';

import { IProductSizesListProps } from './models';
import { productSizesListSchema } from './ProductSizesList.schema';

import './ProductSizesList.scss';

const ProductSizesList: FC<IProductSizesListProps> = ({ items }) => {
  const { data }: { data: IProductSizesListProps } = useJoiValidation(
    productSizesListSchema,
    'ProductSizesList',
    {
      items,
    }
  );

  return (
    <div className="product-sizes-list">
      {data?.items?.map((item: ISizeVariantProps) => (
        <SizeVariant key={item.name} {...item} />
      ))}
    </div>
  );
};
export default ProductSizesList;
