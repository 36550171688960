import { ISplashScreenProps } from '@root/components/SplashScreen/models';
import Joi from 'joi';

export const splashScreenSchema = Joi.object<ISplashScreenProps>({
  ariaClose: Joi.string().required(),
  isModalShowedOnlyOnce: Joi.boolean().required(),
  isModalClosedAfterVideoEnd: Joi.boolean().required(),
  isMobileOnly: Joi.boolean().required(),
  isVideoAutoplayed: Joi.boolean().required(),
  useFallbackImage: Joi.boolean().required(),
  videoUrl: Joi.string().required(),
  noSupportMessage: Joi.string().required(),
  onCloseLocalStorageValue: Joi.string().required(),
  fallbackVideoImage: Joi.object({
    url: Joi.string().uri().required(),
  }).optional(),
}).options({ stripUnknown: true });
