import React from 'react';
import { CustomComponentsProvider } from '@husky-x/gatsby-theme-husky';

import ProductSizesSection from './src/components/ProductSizesSection';
import SplashScreen from './src/components/SplashScreen';
import VideoTeaser from './src/components/VideoTeaser';
import WhereToBuy from './src/components/WhereToBuy';

export const wrapRootElement = ({ element }) => (
  <CustomComponentsProvider
    list={[
      {
        key: 'productSizes',
        component: ProductSizesSection,
      },
      {
        key: 'whereToBuy',
        component: WhereToBuy,
      },
      {
        key: 'splashScreen',
        component: SplashScreen,
      },
      {
        key: 'videoTeaser',
        component: VideoTeaser,
      },
    ]}
  >
    {element}
  </CustomComponentsProvider>
);
