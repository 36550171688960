import { IProductSizesSectionProps } from '@root/components/ProductSizesSection/models';
import Joi from 'joi';

const stringRequired = Joi.string().required();
const uriRequired = Joi.string().uri().required();
const imgSchema = {
  url: uriRequired,
  filename: Joi.string().required(),
};

const requiredImageSchema = Joi.object(imgSchema).required();
const optionalImageSchema = Joi.object(imgSchema);

export const productSizesSectionSchema = Joi.object<IProductSizesSectionProps>({
  ctaOpen: stringRequired,
  ctaClose: stringRequired,
  trialPackSize: stringRequired,
  trialPackName: stringRequired,
  experiencePackName: stringRequired,
  experiencePackSize: stringRequired,
  superValuePackName: stringRequired,
  superValuePackSize: stringRequired,
  conveniencePackSize: stringRequired,
  conveniencePackName: stringRequired,
  valuePackSize: stringRequired,
  valuePackName: stringRequired,
  modalTitle: stringRequired,
  modalImage: requiredImageSchema,
  modalDescription: stringRequired,
  inTeaser: Joi.boolean().required(),
  teaserImage: optionalImageSchema,
  teaserDescription: Joi.string().allow(''),
  teaserTitle: Joi.string().allow(''),
  teaserImageAlt: Joi.string().allow(''),
}).options({ stripUnknown: true });
