import React, { FC } from 'react';
import { Typography } from '@design-system/typography';
import useJoiValidation from '@root/hooks/useJoiValidation';

import { IVideoTeaserProps } from './models';
import { videoTeaserSchema } from './VideoTeaser.schema';

import './VideoTeaser.scss';

const VideoTeaser: FC<IVideoTeaserProps> = ({
  videoUrl,
  teaserTitle,
  teaserSubtitle,
  noSupportMessage,
  fallbackVideoImage,
  useFallbackImage,
}) => {
  const { data }: { data: IVideoTeaserProps } = useJoiValidation(videoTeaserSchema, 'VideoTeaser', {
    videoUrl,
    teaserTitle,
    teaserSubtitle,
    noSupportMessage,
    fallbackVideoImage,
    useFallbackImage,
  });

  console.log(fallbackVideoImage);

  return (
    <div className="video-teaser">
      <div className="video-teaser__wrapper">
        <div className="video-teaser__text">
          <Typography
            className="video-teaser__title"
            tag="h2"
            variant="body"
            size="xl"
            content={data.teaserTitle}
            align="start"
            weight="bold"
            wrapping="default"
          />
          <Typography
            className="video-teaser__subtitle"
            tag="h3"
            variant="body"
            size="m"
            content={data.teaserSubtitle}
            align="start"
            weight="regular"
            wrapping="default"
          />
        </div>
        <video
          className="video-teaser__video"
          preload="auto"
          muted
          playsInline
          autoPlay
          controls={false}
          src={data.videoUrl}
          poster={
            (data.useFallbackImage &&
              data?.fallbackVideoImage?.gatsbyImageData?.images?.fallback?.src) ||
            undefined
          }
        >
          {data.noSupportMessage}
        </video>
      </div>
    </div>
  );
};

export default VideoTeaser;
