import { useCallback, useEffect, useState } from 'react';
import Joi from 'joi';

const useJoiValidation = (schema: Joi.ObjectSchema, componentName: string, initialData) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState<string | null>(null);

  const validate = useCallback((): void => {
    const { error: validationError } = schema.validate(data, { abortEarly: false });

    if (validationError) {
      setError(
        `${componentName} validation error: ${validationError.details
          .map((err) => err.message)
          .join(', ')}`
      );
    } else {
      setError(null);
    }
  }, [componentName, data, schema]);

  const setValidatedData = (newData): void => {
    setData(newData);
  };

  const throwValidationErrors = useCallback((): void => {
    validate();
    if (error) {
      throw new Error(error);
    }
  }, [error, validate]);

  useEffect(() => {
    throwValidationErrors();
  }, [data, throwValidationErrors]);

  return { data, setData: setValidatedData, throwValidationErrors };
};

export default useJoiValidation;
