import React, { FC, useCallback, useState } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';
import { Tab, Tabs } from '@design-system/tabs';
import { useScreenRecognition } from '@phx-husky/use-screen-recognition';
import ControlledModal from '@root/components/ControlledModal';
import useJoiValidation from '@root/hooks/useJoiValidation';
import { gatsbyImageMapper } from '@theme-husky/mappers/image';

import { IWhereToBuyProps } from './models';
import { whereToBuySchema } from './WhereToBuy.schema';

import './WhereToBuy.scss';

const WhereToBuy: FC<IWhereToBuyProps> = ({
  ctaOpen,
  ctaClose,
  imageAlt,
  imageMobileBuyOnline,
  imageMobileBuyNearby,
  ctaBuyOnline,
  ctaBuyNearby,
  imageDesktop,
}) => {
  const {
    data,
  }: {
    data: IWhereToBuyProps;
  } = useJoiValidation(whereToBuySchema, 'WhereToBuy', {
    ctaOpen,
    ctaClose,
    imageAlt,
    imageMobileBuyOnline,
    imageMobileBuyNearby,
    ctaBuyOnline,
    ctaBuyNearby,
    imageDesktop,
  });
  const { isMobile } = useScreenRecognition();

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = useCallback((newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <div className="where-to-buy">
      <div className="cds-container cds-container--full-width">
        <ControlledModal
          openButtonLabel={data.ctaOpen}
          closeButtonLabel={data.ctaClose}
          className="where-to-buy-modal"
        >
          {isMobile && data?.imageMobileBuyOnline ? (
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab key="findOnline" title={data.ctaBuyOnline}>
                <ExtendableImage
                  element={gatsbyImageMapper({
                    alt: data.imageAlt || 'Where to buy',
                    image: data.imageMobileBuyOnline,
                    objectFit: 'contain',
                  })}
                />
              </Tab>
              <Tab key="findNearby" title={data.ctaBuyNearby}>
                <ExtendableImage
                  element={gatsbyImageMapper({
                    alt: data.imageAlt || 'Where to buy',
                    image: data.imageMobileBuyNearby,
                    objectFit: 'contain',
                  })}
                />
              </Tab>
            </Tabs>
          ) : (
            data?.imageDesktop && (
              <ExtendableImage
                element={gatsbyImageMapper({
                  alt: data.imageAlt || 'Where to buy',
                  image: data.imageDesktop,
                  objectFit: 'contain',
                })}
              />
            )
          )}
        </ControlledModal>
      </div>
    </div>
  );
};

export default WhereToBuy;
